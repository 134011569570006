<template>
  <div>
    <b-sidebar id="sidebar-add-new-event" sidebar-class="sidebar-lg" :visible="isEventHandlerSidebarActive"
      bg-variant="white" shadow backdrop no-header right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Update' : 'Add' }} Booking
          </h5>
          <div>
            <!-- <feather-icon v-if="eventLocal.id" icon="TrashIcon" class="cursor-pointer"
              @click="$emit('remove-event'); hide();" /> -->
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent=" handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-row>
              <b-col md="6">
                <!-- Start Date -->
                <validation-provider #default="validationContext" name="Start Date" rules="required">

                  <b-form-group label="Start Date" label-for="start-date" :state="getValidationState(validationContext)">
                    <date-time-picker v-model="eventLocal.start" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- End Date -->
                <validation-provider #default="validationContext" name="End Date" rules="required">

                  <b-form-group label="End Date" label-for="end-date" :state="getValidationState(validationContext)">
                    <date-time-picker v-model="eventLocal.end" :minDate="eventLocal.start" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </b-col>
            </b-row>

            <!-- Hospital -->
            <validation-provider #default="validationContext" name="Hospital" rules="required">
              <b-form-group label="Hospital" label-for="hospital" :state="getValidationState(validationContext)">

                <v-select id="hospital" v-model="eventLocal.extendedProps.client" :clearable="false" label="text"
                  :options="clients" :reduce="option => option.value" :state="getValidationState(validationContext)" />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Assistant -->
            <validation-provider #default="validationContext" name="Assistant" rules="required">
              <b-form-group label="Assistant" label-for="assistant" :state="getValidationState(validationContext)">

                <v-select id="assistant" v-model="eventLocal.extendedProps.assistant" :clearable="false" label="text"
                  :options="users" :reduce="option => option.value" :state="getValidationState(validationContext)" />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="d-flex my-2" v-if="eventLocal.id && eventLocal.extendedProps.status == -1">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="mr-1"
                v-b-modal.modal-reason>
                Reject
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" @click="accept" class="mr-1">
                Accept
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" v-b-modal.modal-delete>
                Delete
              </b-button>
            </div>

            <div class="d-flex my-2" v-if="eventLocal.id && eventLocal.extendedProps.status != -1 && enableDelete">
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" v-b-modal.modal-delete>
                Delete
              </b-button>
            </div>
            <!-- All Day -->
            <!-- <b-form-group>
              <b-form-checkbox v-model="eventLocal.allDay" name="check-button" switch inline>
                All Day
              </b-form-checkbox>
            </b-form-group> -->

            <!-- Event URL -->
            <!-- <validation-provider #default="validationContext" name="Event URL" rules="url">

              <b-form-group label="Event URL" label-for="event-url">
                <b-form-input id="event-url" v-model="eventLocal.eventUrl" type="url"
                  :state="getValidationState(validationContext)" placeholder="htttps://www.google.com" trim />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <!-- Guests -->
            <!-- <b-form-group label="Add Guests" label-for="add-guests">
              <v-select v-model="eventLocal.extendedProps.guests" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple :close-on-select="false" :options="guestsOptions" label="name" input-id="add-guests">

                <template #option="{ avatar, name }">
                  <b-avatar size="sm" :src="avatar" />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar size="sm" class="border border-white" :src="avatar" />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group> -->

            <!-- Location -->
            <!-- <b-form-group label="Location" label-for="event-location">
              <b-form-input id="event-location" v-model="eventLocal.extendedProps.location" trim
                placeholder="Event Location" />
            </b-form-group> -->

            <!-- Textarea -->
            <!-- <b-form-group label="Description" label-for="event-description">
              <b-form-textarea id="event-description" v-model="eventLocal.extendedProps.description" />
            </b-form-group> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                {{ eventLocal.id ? 'Update Slot' : 'Book slot' }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <b-modal id="modal-reason" ref="my-modal" title="Submit Your Name" ok-title="Submit"
      cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleReasonSubmit">
        <b-form-group :state="reasonState" label="Please provide a rejection reason: *" label-for="name-input"
          invalid-feedback="Reason is required">
          <b-form-textarea id="name-input" v-model="reason" :state="reasonState" required />
        </b-form-group>
      </form>
    </b-modal>

    <b-modal id="modal-delete" ref="my-modal-delete" title="Confirm" ok-title="Yes" cancel-variant="outline-secondary"
      @show="resetModal" @hidden="resetModal" @ok="deleteEvent">
      <h4>Are you sure to delete booking?</h4>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback, BRow, BCol, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import Parse from "parse/dist/parse.min.js";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import DateTimePicker from "@core/components/DateTimePicker.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BRow,
    BCol,
    BAvatar,
    BModal,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    DateTimePicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      users: [],
      clients: [],
      reason: '',
      reasonState: null,
      enableDelete: false
    }
  },
  created() {
    this.fetchClients()
    this.fetchUsers()
  },
  methods: {
    fetchClients() {
      const Client = Parse.Object.extend("Client");
      const query = new Parse.Query(Client);
      query
        .find()
        .then((results) => {
          this.clients = results.map(client => ({
            value: client.id,
            text: `${client.attributes.name} ${client.attributes.department}`
          }));
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching client list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchUsers() {
      const query = new Parse.Query(Parse.Object.extend("User"));


      const Role = Parse.Object.extend("_Role");
      const innerQuery = new Parse.Query(Role);
      innerQuery.equalTo("name", "assistant")

      query.matchesQuery("role", innerQuery);

      query
        .find()
        .then((results) => {
          this.users = results.map(user => ({
            value: user.id,
            text: `${user.attributes.firstName} ${user.attributes.lastName}`
          }));

          this.users.unshift({
            value: "-1",
            text: 'Any assistant'
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching user list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchLogs() {
      const Log = Parse.Object.extend("Log");
      const Booking = Parse.Object.extend("Booking");

      const query = new Parse.Query(Log);

      const booking = new Booking();
      booking.set("objectId", this.eventLocal.id);

      query.equalTo("booking", booking)

      query
        .find()
        .then((results) => {
          if (results.length === 0)
            this.enableDelete = true
        })
        .catch((ex) => {
          this.enableDelete = false
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.reasonState = valid
      return valid
    },
    resetModal() {
      this.reason = ''
      this.reasonState = null
    },
    handleOk(event) {
      this.handleReasonSubmit(event)
    },
    handleReasonSubmit(event) {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        event.preventDefault()
        return
      }

      this.eventLocal.extendedProps.rejectReason = this.reason
      this.eventLocal.extendedProps.status = 0

      this.onSubmit()
    },
    accept() {
      if (this.eventLocal.extendedProps.assistant == "-1") {

        this.toast({
          component: ToastificationContent,
          props: {
            title: "Please assign assistant to accept!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return
      }

      this.eventLocal.extendedProps.status = 1
      this.onSubmit()
    },
    deleteEvent() {
      this.deleteBooking()
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const toast = useToast()

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      deleteBooking,
      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      deleteBooking,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      toast
    }
  },
  watch: {
    eventLocal: function (newVal, oldVal) {
      this.enableDelete = false
      if (newVal.id) {
        this.fetchLogs()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
