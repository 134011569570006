import Parse from "parse/dist/parse.min.js";

// Full Calendar Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import store from "@/store";
import { BACKEND_URL } from "../../../utils/constants";
import moment from "moment";
import axios from "@axios";

export default function userCalendar() {
  // Use toast
  const toast = useToast();
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null);

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null;
  onMounted(() => {
    calendarApi = refCalendar.value.getApi();
  });

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Business: "primary",
    Holiday: "success",
    Personal: "danger",
    Family: "warning",
    ETC: "info",
  };

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: "",
    start: "",
    end: "",
    allDay: false,
    url: "",
    extendedProps: {
      calendar: "",
      guests: [],
      location: "",
      description: "",
      assistant: "",
      client: "",
      status: "",
      rejectReason: "",
    },
  };
  const event = ref(JSON.parse(JSON.stringify(blankEvent)));
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent));
  };

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (
    updatedEventData,
    propsToUpdate,
    extendedPropsToUpdate
  ) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Updated",
        icon: "CheckIcon",
        variant: "success",
      },
    });

    const existingEvent = calendarApi.getEventById(updatedEventData.id);
    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index];
      existingEvent.setProp(propName, updatedEventData[propName]);
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, {
      allDay: updatedEventData.allDay,
    });

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index];
      existingEvent.setExtendedProp(
        propName,
        updatedEventData.extendedProps[propName]
      );
    }
  };

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = (eventId) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Removed",
        icon: "TrashIcon",
        variant: "danger",
      },
    });
    calendarApi.getEventById(eventId).remove();
  };

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = (eventApi) => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        assistant,
        client,
        status,
        rejectReason,
        isAnonymous,
      },
      allDay,
    } = eventApi;

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        assistant,
        client,
        status,
        rejectReason,
        isAnonymous,
      },
      allDay,
    };
  };

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = async (eventData) => {
    const eventStart = new Date(eventData.start);
    const eventEnd = new Date(eventData.end);

    const Booking = Parse.Object.extend("Booking");
    const Client = Parse.Object.extend("Client");
    const Assitant = Parse.Object.extend("_User");

    const assistant = new Assitant();
    assistant.id = eventData.extendedProps.assistant;

    if (assistant.id != -1) {
      const Availability = Parse.Object.extend("Availability");
      const avQuery = new Parse.Query(Availability);
      avQuery.equalTo("assistant", assistant);
      // avQuery.greaterThanOrEqualTo("end", eventEnd);
      // avQuery.lessThanOrEqualTo("start", eventStart);

      const repeatQuery = new Parse.Query(Availability);
      repeatQuery.equalTo("assistant", assistant);
      repeatQuery.equalTo("recurrent", true);

      const availabilties = await Parse.Query.or(avQuery, repeatQuery).find();

      let inAvailableRange = false;

      for (const availability of availabilties) {
        let date = new Date(availability.attributes.date);
        if (
          availability.attributes.recurrent &&
          eventStart.getDay() !== date.getDay()
        ) {
          continue;
        }

        if (availability.attributes.recurrent) {
          date.setFullYear(
            eventStart.getFullYear(),
            eventStart.getMonth(),
            eventStart.getDate()
          );
        }

        const startDate = new Date(date);
        const startTimeComponents = availability.attributes.start.split(":");
        startDate.setHours(
          parseInt(startTimeComponents[0]),
          parseInt(startTimeComponents[1]),
          0
        );
        const endDate = new Date(date);
        const endTimeComponents = availability.attributes.end.split(":");
        endDate.setHours(
          parseInt(endTimeComponents[0]),
          parseInt(endTimeComponents[1]),
          0
        );

        if (eventStart >= startDate && eventEnd <= endDate) {
          inAvailableRange = true;
        }
      }

      if (!inAvailableRange) {
        toast({
          component: ToastificationContent,
          props: {
            title:
              "That assistant is not available. Please select other or any assistant.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }

      const bookingOverlapQuery1 = new Parse.Query(Booking);
      bookingOverlapQuery1.equalTo("assistant", assistant);
      bookingOverlapQuery1.greaterThanOrEqualTo("start", eventStart);
      bookingOverlapQuery1.lessThanOrEqualTo("start", eventEnd);

      const bookingOverlapQuery2 = new Parse.Query(Booking);
      bookingOverlapQuery2.equalTo("assistant", assistant);
      bookingOverlapQuery2.greaterThanOrEqualTo("end", eventStart);
      bookingOverlapQuery2.lessThanOrEqualTo("end", eventEnd);

      const overlappingBookings = await Parse.Query.or(
        bookingOverlapQuery1,
        bookingOverlapQuery2
      ).find();

      if (overlappingBookings.length > 0) {
        toast({
          component: ToastificationContent,
          props: {
            title:
              "That assistant is not available. Please select other or any assistant.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
    }

    const booking = new Booking();

    assistant.id = eventData.extendedProps.assistant;

    const client = new Client();
    client.id = eventData.extendedProps.client;

    booking.set("assistant", assistant);
    booking.set("client", client);
    booking.set("start", new Date(eventData.start));
    booking.set("end", new Date(eventData.end));
    booking.set("status", 1);

    booking.save().then(
      async (booking) => {
        // Execute any logic that should take place after the object is saved.
        refetchEvents();

        const query = new Parse.Query(Parse.Object.extend("User"));

        const Role = Parse.Object.extend("_Role");
        const innerQuery = new Parse.Query(Role);
        innerQuery.equalTo("name", "admin");

        query.matchesQuery("role", innerQuery);
        const result = await query.find();

        const clientQuery = new Parse.Query(Parse.Object.extend("User"));

        const clientMatch = new Client();
        clientMatch.id = client;

        clientQuery.equalTo("client", clientMatch);

        const result1 = await clientQuery.find();

        const json_payload = JSON.stringify({
          title: "New booking created",
          description:
            "A new booking has been created at " +
            moment(new Date(eventData.start)).format("YYYY-MM-DD HH:mm") +
            ".",
          recipients: [...result, ...result1].map((res) => ({
            email: res.attributes.username,
            first_name: res.attributes.firstName,
            last_name: res.attributes.lastName,
          })),
        });

        axios
          .get(`${BACKEND_URL}?action=notification&data=${json_payload}`)
          .then((response) => {});
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        toast({
          component: ToastificationContent,
          props: {
            title: "Failed to create booking",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    );
  };
  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = async (eventData) => {
    const eventStart = new Date(eventData.start);
    const eventEnd = new Date(eventData.end);

    const Booking = Parse.Object.extend("Booking");
    const Client = Parse.Object.extend("Client");
    const Assitant = Parse.Object.extend("_User");

    const assistant = new Assitant();
    assistant.id = eventData.extendedProps.assistant;

    if (eventData.extendedProps.status != 0 && assistant.id != -1) {
      const Availability = Parse.Object.extend("Availability");
      const avQuery = new Parse.Query(Availability);
      avQuery.equalTo("assistant", assistant);
      // avQuery.greaterThanOrEqualTo("end", eventEnd);
      // avQuery.lessThanOrEqualTo("start", eventStart);

      const repeatQuery = new Parse.Query(Availability);
      repeatQuery.equalTo("assistant", assistant);
      repeatQuery.equalTo("recurrent", true);

      const availabilties = await Parse.Query.or(avQuery, repeatQuery).find();

      let inAvailableRange = false;

      for (const availability of availabilties) {
        let date = new Date(availability.attributes.date);
        if (
          availability.attributes.recurrent &&
          eventStart.getDay() !== date.getDay()
        ) {
          continue;
        }

        if (availability.attributes.recurrent) {
          date.setFullYear(
            eventStart.getFullYear(),
            eventStart.getMonth(),
            eventStart.getDate()
          );
        }

        const startDate = new Date(date);
        const startTimeComponents = availability.attributes.start.split(":");
        startDate.setHours(
          parseInt(startTimeComponents[0]),
          parseInt(startTimeComponents[1]),
          0
        );
        const endDate = new Date(date);
        const endTimeComponents = availability.attributes.end.split(":");
        endDate.setHours(
          parseInt(endTimeComponents[0]),
          parseInt(endTimeComponents[1]),
          0
        );

        if (eventStart >= startDate && eventEnd <= endDate) {
          inAvailableRange = true;
        }
      }

      if (!inAvailableRange) {
        toast({
          component: ToastificationContent,
          props: {
            title:
              "That assistant is not available. Please select other or any assistant.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
    }

    if (assistant.id != -1) {
      const bookingOverlapQuery1 = new Parse.Query(Booking);
      bookingOverlapQuery1.equalTo("assistant", assistant);
      bookingOverlapQuery1.greaterThanOrEqualTo("start", eventStart);
      bookingOverlapQuery1.lessThanOrEqualTo("start", eventEnd);
      bookingOverlapQuery1.notEqualTo("objectId", eventData.id);

      const bookingOverlapQuery2 = new Parse.Query(Booking);
      bookingOverlapQuery2.equalTo("assistant", assistant);
      bookingOverlapQuery2.greaterThanOrEqualTo("end", eventStart);
      bookingOverlapQuery2.lessThanOrEqualTo("end", eventEnd);
      bookingOverlapQuery2.notEqualTo("objectId", eventData.id);

      const overlappingBookings = await Parse.Query.or(
        bookingOverlapQuery1,
        bookingOverlapQuery2
      ).find();

      if (overlappingBookings.length > 0) {
        toast({
          component: ToastificationContent,
          props: {
            title:
              "That assistant is not available. Please select other or any assistant.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
    }

    const existingBooking = new Parse.Query(Booking);
    existingBooking.equalTo("objectId", eventData.id);

    const existingBookingObj = await existingBooking.find();
    const oldStatus = existingBookingObj[0].attributes.status;

    const booking = new Booking();

    assistant.id = eventData.extendedProps.assistant;

    const client = new Client();
    client.id = eventData.extendedProps.client;

    booking.set("id", eventData.id);
    booking.set("assistant", assistant);
    booking.set("client", client);
    booking.set("start", new Date(eventData.start));
    booking.set("end", new Date(eventData.end));
    booking.set("status", eventData.extendedProps.status);
    booking.set("rejectReason", eventData.extendedProps.rejectReason);

    booking.save().then(
      async (result) => {
        // Execute any logic that should take place after the object is saved.
        toast({
          component: ToastificationContent,
          props: {
            title: "Event Updated",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        refetchEvents();

        if (eventData.extendedProps.status != oldStatus) {
          const query = new Parse.Query(Parse.Object.extend("User"));

          const Role = Parse.Object.extend("_Role");
          const innerQuery = new Parse.Query(Role);
          innerQuery.equalTo("name", "admin");

          query.matchesQuery("role", innerQuery);
          const result = await query.find();

          const clientQuery = new Parse.Query(Parse.Object.extend("User"));

          const clientMatch = new Client();
          clientMatch.id = client;

          clientQuery.equalTo("client", clientMatch);

          const result1 = await clientQuery.find();

          let description = "";

          if (eventData.extendedProps.status === 1) {
            description =
              "A new booking at " +
              moment(new Date(eventData.start)).format("YYYY-MM-DD HH:mm") +
              " has been approved.";
          } else if (eventData.extendedProps.status === 0) {
            description =
              "A new booking at " +
              moment(new Date(eventData.start)).format("YYYY-MM-DD HH:mm") +
              " has been rejected with following reason: " +
              eventData.extendedProps.rejectReason;
          }

          const json_payload = JSON.stringify({
            title:
              "A booking has been " +
              (eventData.extendedProps.status === 1 ? "approved" : "rejected"),
            description,
            recipients: [...result, ...result1].map((res) => ({
              email: res.attributes.username,
              first_name: res.attributes.firstName,
              last_name: res.attributes.lastName,
            })),
          });

          axios
            .get(`${BACKEND_URL}?action=notification&data=${json_payload}`)
            .then((response) => {});
        }
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        toast({
          component: ToastificationContent,
          props: {
            title: "Failed to create booking",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    );
  };

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id;
    store.dispatch("calendar/removeEvent", { id: eventId }).then(() => {
      removeEventInCalendar(eventId);
    });
  };

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents();
  };

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(
    () => store.state.calendar.selectedCalendars
  );

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedClient = computed(() => store.state.calendar.selectedClient);

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedAssistant = computed(
    () => store.state.calendar.selectedAssistant
  );

  // ------------------------------------------------
  // availability
  // ------------------------------------------------
  const availability = computed(() => store.state.calendar.availability);

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedStatus = computed(() => store.state.calendar.selectedStatus);

  watch(selectedCalendars, () => {
    refetchEvents();
  });

  watch(selectedClient, () => {
    refetchEvents();
  });

  watch(selectedAssistant, () => {
    refetchEvents();
  });

  watch(availability, () => {
    refetchEvents();
  });

  watch(selectedStatus, () => {
    refetchEvents();
  });

  const deleteBooking = (info) => {
    const Booking = Parse.Object.extend("Booking");
    const query = new Parse.Query(Booking);
    query.equalTo("objectId", info.id);
    query.find().then(async (res) => {
      await res[0].destroy({});
      refetchEvents();
    });
  };
  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return;

    const Booking = Parse.Object.extend("Booking");
    const query = new Parse.Query(Booking);
    if (selectedAssistant.value) {
      const Assitant = Parse.Object.extend("_User");
      const assistant = new Assitant();
      assistant.id = selectedAssistant.value;
      query.equalTo("assistant", assistant);
    }

    if (selectedClient.value) {
      const Client = Parse.Object.extend("Client");
      const client = new Client();
      client.id = selectedClient.value;
      query.equalTo("client", client);
    }

    if (selectedStatus.value != -2) {
      query.equalTo("status", selectedStatus.value);
    }

    query.greaterThanOrEqualTo("start", info.start);
    query.lessThanOrEqualTo("start", info.end);

    query
      .find()
      .then((res) => {
        const events = res.map((result) => ({
          title: `${
            result.attributes.assistant.attributes.firstName ?? "Any"
          } ${
            result.attributes.assistant.attributes.lastName ?? "assistant"
          } - ${result.attributes.client.attributes.name} ${
            result.attributes.client.attributes.department
          }`,
          start: result.attributes.start,
          end: result.attributes.end,
          extendedProps: {
            description: `${result.attributes.client.attributes.name} ${result.attributes.client.attributes.department}`,
            client: result.attributes.client.id,
            assistant: result.attributes.assistant.id,
            status: result.attributes.status,
            rejectReason: result.attributes.rejectReason,
            calendar:
              result.attributes.status == -1
                ? "Business"
                : result.attributes.status == 0
                ? "Personal"
                : "Holiday",
            isAnonymous: result.attributes.isAnonymous,
          },
          id: result.id,
        }));
        if (availability.value) {
          const Availability = Parse.Object.extend("Availability");
          const avQuery = new Parse.Query(Availability);
          if (selectedAssistant.value) {
            const Assitant = Parse.Object.extend("_User");
            const assistant = new Assitant();
            assistant.id = selectedAssistant.value;
            avQuery.equalTo("assistant", assistant);
          }

          // avQuery.greaterThanOrEqualTo("date", info.start);
          // avQuery.lessThanOrEqualTo("date", info.end);

          const repeatQuery = new Parse.Query(Availability);
          const Assitant = Parse.Object.extend("_User");
          if (selectedAssistant.value) {
            const assistant = new Assitant();
            assistant.id = selectedAssistant.value;
            repeatQuery.equalTo("assistant", assistant);
          }

          repeatQuery.equalTo("recurrent", true);

          Parse.Query.or(avQuery, repeatQuery)
            .find()
            .then((results) => {
              const availabilities = results.map((result) => {
                let date = new Date(result.attributes.date);
                if (result.attributes.recurrent) {
                  date = new Date(info.start).addDays(
                    date.getDay() != 0 ? date.getDay() - 1 : 6
                  );
                  // date = new Date(info.start).addDays(date.getDay());
                }
                const startDate = new Date(date);
                const startTimeComponents = result.attributes.start.split(":");
                startDate.setHours(
                  parseInt(startTimeComponents[0]),
                  parseInt(startTimeComponents[1]),
                  0
                );
                const endDate = new Date(date);
                const endTimeComponents = result.attributes.end.split(":");
                endDate.setHours(
                  parseInt(endTimeComponents[0]),
                  parseInt(endTimeComponents[1]),
                  0
                );

                const checkExistingBookingStart = new Parse.Query(Booking);
                const assistant = new Assitant();
                assistant.id = result.attributes.assistant.id;
                checkExistingBookingStart.equalTo("assistant", assistant);

                checkExistingBookingStart.greaterThanOrEqualTo(
                  "start",
                  startDate
                );
                checkExistingBookingStart.lessThanOrEqualTo("start", endDate);

                const checkExistingBookingEnd = new Parse.Query(Booking);
                checkExistingBookingEnd.equalTo("assistant", assistant);

                checkExistingBookingEnd.greaterThanOrEqualTo("end", startDate);
                checkExistingBookingEnd.lessThanOrEqualTo("end", endDate);

                return {
                  title: `Available slot: ${result.attributes.assistant.attributes.firstName} ${result.attributes.assistant.attributes.lastName}`,
                  start: startDate,
                  end: endDate,
                  extendedProps: {
                    description: ``,
                    assistant: result.attributes.assistant.id,
                    startTime: result.attributes.start,
                    endTime: result.attributes.end,
                    recurrent: result.attributes.recurrent,
                    date: date,
                    calendar: `${result.attributes.assistant.attributes.firstName} ${result.attributes.assistant.attributes.lastName}`,
                    isAvailability: true,
                  },
                  id: result.id,
                  display: "background",
                };
              });
              successCallback(events.concat(availabilities));
            })
            .catch((ex) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching availabilities",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          successCallback(events);
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching bookings",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: "timeGridWeek",
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      end: "timeGridWeek,listMonth",
    },
    events: fetchEvents,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName =
        calendarsColor[calendarEvent._def.extendedProps.calendar];

      return [
        // Background Color
        `bg-light-${colorName}`,
      ];
    },
    eventClick(info) {
      const clickedEvent = info.event;
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      if (clickedEvent.extendedProps.isAvailability) {
        event.value = JSON.parse(JSON.stringify(Object.assign(event.value)));
      } else {
        event.value = grabEventDataFromEventApi(clickedEvent);
      }
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true;
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: "sidebar",
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value =
            !isCalendarOverlaySidebarActive.value;
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(
        JSON.stringify(Object.assign(event.value, { start: info.date }))
      );
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true;
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent));
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent));
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? "rtl" : "ltr")),
    rerenderDelay: 350,
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    },
    firstDay: 1,
    slotLabelFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    },
    dayHeaderFormat: { day: "numeric", weekday: "short", omitCommas: true },
  });

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false);

  const isCalendarOverlaySidebarActive = ref(false);

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    deleteBooking,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  };
}
