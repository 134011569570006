<template>
  <date-picker v-model="model" mode="dateTime" is24hr :minute-increment="15" :min-date="minDate">
    <template v-slot="{ inputValue, inputEvents }">
      <input class="input-container border rounded focus:outline-none focus:border-blue-300" :value="inputValue"
        v-on="inputEvents" style="padding: 8px;" />
    </template>
  </date-picker>
</template>
  
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    minDate: {
      type: String,
      default: null
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        if (value)
          this.$emit('input', value.toISOString())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  padding: 8px !important;
}
</style>
